import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../Login/LoginScreen.css'; 
import Header from '../common/header';
import referralImage from '../../../assets/images/referandearn.png'; 
import whatsappIcon from '../../../assets/images/what.png'; 
import instagramIcon from '../../../assets/images/insta.png'; 
import telegramIcon from '../../../assets/images/tele.png'; 
import shareIcon from '../../../assets/images/share.png'; 
import copyimg from '../../../assets/images/copy.png'; 
import Container from '../../container';
import { fetchUserdetail } from '../../../api/api';

const ReferAndEarn = () => {
    const location = useLocation();
    const [referralId, setReferralId] = useState('');
    const [referralCount, setReferralCount] = useState(0);
    const [copySuccess, setCopySuccess] = useState('');
    const [AmountWid, setAmountWid] = useState(0);
    const [isFaultVisible, setIsFaultVisible] = useState(false);
    const [keyFrom, setKeyFrom] = useState('');

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(referralId || 'AbCdEfGhIjK');
            setCopySuccess('Copied!');
        } catch (err) {
            setCopySuccess('Failed to copy!');
        }

        setTimeout(() => {
            setCopySuccess('');
        }, 2000);
    };

    useEffect (( )=>{
        handlefetchUserdetail()
    },[])
    

    const handlefetchUserdetail = async () => {
        try {
            const data = await fetchUserdetail();
            console.log(data, "user API response");
            setAmountWid(data?.data?.points);
            setReferralId(data?.data?.referral_id);
            setReferralCount(data?.data?.referral_count ?? 0);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };
    function openNativeShare(referralId) {
        if (navigator.share) {
            navigator.share({
                title: 'Check out this app, Payme!',
                text: `I'm sharing this app with you! Enroll now and instantly get referral Coins. Use my referral ID: ${referralId}.`,
                url: "https://paisebnao.com/"
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch((error) => {
                console.error('Error sharing:', error);
            });
        } else {
            alert('Your browser does not support the native share feature.');
        }
    }
    
    function shareOnSocialMedia(platform, referralId) {
        let url = '';
        const message = `I'm sharing this app with you! Enroll now and instantly get ₹100. Use my referral ID: ${referralId}.`;
    
        switch(platform) {
            case 'whatsapp':
                url = `https://wa.me/9769335392?text=${encodeURIComponent(message)}`;
                break;
            case 'telegram':
                url = `https://t.me/share/url?url=${encodeURIComponent('https://example.com')}&text=${encodeURIComponent(message)}`;
                break;
            case 'instagram':
                url = 'https://www.instagram.com/yourprofile/';
                break;
            default:
                console.log('Unsupported platform');
                return;
        }
    
        window.open(url, '_blank', 'noopener noreferrer');
    }
    
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setKeyFrom(params.get('keyfrom')); 
    }, [location]);

    return (
        <Container>
            <div className="refer-container">
                <div className="flex-row mt-10">
                    <Header />
                </div>

                {/* Show refer-status based on keyFrom */}
                {keyFrom === 'withdrawal' && (
                    <div className="refer-status">
                        {referralCount <= 2  ? (
                            <>
                                To withdraw {AmountWid}, you need to refer 3 friends <span className="refer-count">{referralCount ?? 0}/3</span>
                            </>
                        ) : (
                            <>Congratulations! Your refer count is {referralCount}, eligible for {AmountWid} withdrawal.</>
                        )}
                    </div>
                )}

                <h2 className="refer-title">Refer & Earn</h2>
                <span className='referandshare'>Refer your Friend & Family and earn ₹ 100 Each</span>
                <img src={referralImage} alt="Refer and Earn" className="referral-image" />
                <div className="refer-steps">
                    <div className="step">
                        <span className="step-number">01</span>
                        <p>Refer your friends.</p>
                    </div>
                    <div className="divider"></div>
                    <div className="step">
                        <span className="step-number">02</span>
                        <p>Let them log in with your referral code.</p>
                    </div>
                    <div className="divider"></div>
                    <div className="step">
                        <span className="step-number">03</span>
                        <p>Get 100 instantly.</p>
                    </div>
                </div>

                <div className="referral-code-container outlinediv">
                    <label className="referral-code-label">Your Referral Code</label>
                    <div className="referral-code-box">
                        <span className="referral-code">{referralId || 'AbCdEfGhIjK'}</span>
                        <div className="copy-button" onClick={copyToClipboard}>
                            {copySuccess ? <span className="copy-success">{copySuccess}</span> : <span>Tap to copy</span>}
                            <img className='ml-5' width={15} src={copyimg} />
                        </div>
                    </div>
                </div>

                <div className="social-invite-container outlinediv">
                    <label className="referral-code-label mb-20">Invite by Social Media</label>
                    <div className='flex-col-center' onClick={() => openNativeShare(referralId)}>
                        <img src={shareIcon} alt="Share" className="social-icon-share" />
                        <button className='white-refer mt-10 top-button refer-earn'>Refer Now</button>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default ReferAndEarn;
